<template>
  <div class="info-table-root">
    <div class="info-box">
      <div class="info-cell" v-for="(item,index) in tableHeader" :key="index" :style="'width:'+item.width">
        <div class="info-title">{{ item.value }}</div>
        <div class="info-content">{{ tableData[item.label] }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "infoTable",
  props: {
    tableHeader: {
      type: Array
    },
    tableData: {
      type: Object
    },
  },
  setup(){
    // onMounted(()=>{
    //   console.log(tableData);
    // })
  }
}
</script>

<style lang="scss" scoped>
.info-table-root {
  .info-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .info-cell {
      box-sizing: border-box;
      border: 1px solid #e5e3e3;

      .info-title {
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        font-weight: 600;
        background-color: #eeeeee;
        text-align: center;
      }

      .info-content {
        width: 100%;
        line-height: 36px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
</style>