<template>
  <div class="login-root">
    <div class="center-content">
      <div class="login-img">
        <img src="../assets/FileManagerLogo.png" alt="login">
      </div>
      <div class="login-acount">
        <el-input v-model="acountNumber" placeholder="请输入账号"></el-input>
        <el-input v-model="password" placeholder="请输入密码" show-password></el-input>
      </div>
      <div class="login-btn">
        <el-button @click="gotoManager">登录</el-button>
      </div>
    </div>
    <bottom-link></bottom-link>
  </div>

</template>

<script>
import bottomLink from "@/components/bottomLink";
import {inject, ref} from "vue";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {useStore} from "vuex";

export default {
  name: "loginPage",
  components:{
    bottomLink
  },
  setup(){
    const router = useRouter();
    const API = inject("API");
    const store = useStore();
    //const route = useRoute();
    let password = ref("");
    let acountNumber = ref("");
    function gotoManager(){
      API({
        url:"login",
        method:"post",
        data:{
          userAccount:acountNumber.value,
          password:password.value
        }
      }).then((res)=>{
        if(res.data.data.length===0){
          acountNumber.value = "";
          password.value = "";
          ElMessage.error('不好意思，没有此账户！')
        }else {
          store.commit('updateUserInfo',res.data.data[0]);
          router.push('/ManagerPage')
        }
        /*let blob = new Blob([res.data.file.data]);
        let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
        let a = document.createElement('a');
        a.href = url;
        a.download = '1815398033.jpeg';
        a.click();
        window.URL.revokeObjectURL(url);*/
      })

    }
    return{
      password,acountNumber,
      gotoManager
    }
  }
}
</script>

<style scoped>
.login-root{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-root .center-content{
  width: 350px;
  height: 350px;
}
.login-root .center-content .login-img{
  display: flex;
  justify-content: center;
}
.login-root .center-content .login-acount .el-input{
  margin-bottom: 12px;
  width: 240px;
  margin-left: 55px;
}
.login-btn{
  display: flex;
  justify-content: center;
}
.login-btn .el-button{
  width: 240px;
}
</style>