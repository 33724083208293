import {createRouter, createWebHashHistory,} from "vue-router";

import HelloWorld from "../components/HelloWorld";
import LoginPage from "../components/loginPage";
import ManagerPage from "../components/managerPage";
import FileManager from "../components/fileManagerSystem/fileManager";
import FileDownload from "../components/fileManagerSystem/fileDownload";
import FileUpload from "../components/fileManagerSystem/fileUpload";
import StockAnalyze from "../components/stockAnalyzeSystem/stockAnalyze";
import StockDetail from "../components/stockAnalyzeSystem/stockDetail";
import StockManager from "../components/stockAnalyzeSystem/stockManager";
import StockInput from "../components/stockAnalyzeSystem/stockInput";
import MethodAnalyze from "../components/stockAnalyzeSystem/methodAnalyze";

const routes = [
    {path: "/", component: LoginPage},
    {
        path: "/ManagerPage",
        component: ManagerPage,
        children:[
            {///ManagerPage/FileDownload
                path:'/ManagerPage/FileManager',
                component:FileManager,
            },
            {
                path:'',
                component:FileDownload,
            },
            {
                path:'/ManagerPage/FileUpload',
                component:FileUpload,
            },
            {
                path:'/ManagerPage/StockAnalyze',
                name:'StockAnalyze',
                component:StockAnalyze,
            },
            {
                path:'/ManagerPage/StockDetail',
                name:'StockDetail',
                component:StockDetail,
            },
            {
                path:'/ManagerPage/StockInput',
                component:StockInput,
            },
            {
                path:'/ManagerPage/StockManager',
                component:StockManager,
            },
            {
                path:'/ManagerPage/MethodAnalyze',
                name: 'MethodAnalyze',
                component:MethodAnalyze,
            },
        ]
    },
    {path: "/HelloWorld", component: HelloWorld},
]


const router = createRouter({
    routes,
    //history: createWebHistory('/')
    history:createWebHashHistory(process.env.BASE_URL),
})

export default router