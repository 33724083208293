<template>
  <div id="m-file-box">
    <el-container>
      <el-header style="text-align:right;font-size:0.75rem">
        <el-popover placement="left-end" :width="400" trigger="click">
          <template #reference>
            <el-button type="primary">文件上传</el-button>
          </template>
          <file-uploader @uploadFail="uploadFail" @eventIsNum="receiveChildNum"></file-uploader>
        </el-popover>
      </el-header>
      <el-main>
        <el-table :data="tableData">
          <el-table-column prop="number" label="序号">
          </el-table-column>
          <el-table-column prop="name" label="文件名称">
          </el-table-column>
          <el-table-column prop="type" label="文件类型">
          </el-table-column>
          <el-table-column prop="size" label="文件大小">
          </el-table-column>
          <el-table-column prop="state" label="状态">
          </el-table-column>
          <el-table-column label="进度">
            <el-progress :percentage="100" status="success"></el-progress>
          </el-table-column>
          <el-table-column label="预览">
<!--            #default="scope"-->
            <template>
              <el-button type="text" size="small">预览</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {provide, reactive, ref} from "vue";
import fileUploader from './fileuploader/file-uploader';

export default {
  name: 'fileUpload',
  setup() {
    let tableData = reactive([]);
    const AllTableData = [];
    const myHeaders = reactive({
      "content-type": "application/x-www-form-urlencoded:charset=UTF-8"
    });
    //const router = useRouter();
    let sheetName = [];
    let excelData = [];
    let fileList = reactive({name: "小罗"});
    let openExcel = ref(false);
    let pageLength = ref(1);
    let pageIndex = ref(1);
    provide('sheetName', sheetName);
    provide('excelData', excelData);
    provide('tableData', tableData);

    function onFileAdded(file) {
      // 设置headers等参数的值
      AllTableData.push({
        number: AllTableData.length + 1,
        name: file.name,
        type: '表格',
        size: file.size / 1000 + "kb",
        state: "成功",
        file: file.file
      });
      pageLength.value = AllTableData.length;
      if (pageLength.value < 11) {
        tableData = AllTableData;
      } else {
        tableData = [];
        for (let i = pageIndex.value; i < pageIndex.value + 10; i++) {
          tableData.push(AllTableData[i - 1]);
        }
      }
    }

    function receiveChildNum(e) {
      console.log(e, '接收子组件数字');
    }

    function deleteRow(index, rows) {
      rows.splice(index, 1);
      console.log(tableData);
    }

    function proPage(page) {
      pageIndex.value = page;
      tableData = [];
      for (let i = page * 10; i > page * 10 - 10; i--) {
        tableData.push(AllTableData[(page - 1) * 10 + page * 10 - i]);
      }
    }

    function getSonchange(flag) {
      openExcel.value = flag;
      sheetName = [];
      excelData = [];
    }

    return {
      tableData,
      AllTableData,
      sheetName,
      excelData,
      openExcel,
      pageLength,
      pageIndex,
      myHeaders,
      fileList,
      onFileAdded,
      deleteRow,
      proPage,
      getSonchange,
      receiveChildNum
    }
  },
  components: {
    fileUploader
  },
}
</script>
<style lang="css">
#m-file-box {
  width: 100%;

}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 3.75rem;
}

</style>
