<template>
  <div id="app">
    <!--  <div ref="chart" style="width: 400px;height: 400px">
    <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023009892号-1</a>
      </div>-->
    <router-view></router-view>
  </div>

</template>

<script>
export default {
  name: "App",
}
</script>

<style scoped>
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
</style>