<template>
  <el-upload class="upload-demo" drag
      :data="fileList" :on-success="uploadSuccess" :on-error="uploadFail" :show-file-list="false"
      action="http://127.0.0.1:8090/api/uploadFile" multiple :before-upload="beforeUpload">
    <el-icon class="el-icon--upload"><upload-filled/></el-icon>
    <div class="el-upload__text">
      拖拽至此处或 <em>点击上传</em>
    </div>
    <template #tip>
      <div class="el-upload__tip">
         files with a size less than 10mb
      </div>
    </template>
  </el-upload>
</template>

<script>

import {inject, reactive} from "vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: "file-uploader",
  setup(){
    let fileList = reactive({name: "小罗"});
    let fileInfomations = [];
    const store = new useStore();
    function beforeUpload(file) {
      if(file.size / 1024 / 1024 > 10||!store.state.userInfo.can_upload){
        ElMessage.warning({
          message: '上传文件过大或权限不足。',
          type: 'warning',
        })
        return false
      }
      fileList.name = file.name;
      fileInfomations.push(file);
    }
    let tableData = inject('tableData');
    function uploadSuccess(){
      let fileInformation = {
        number: tableData.length + 1,
        name: fileInfomations[tableData.length].name,
        type: fileInfomations[tableData.length].type,
        size: fileInfomations[tableData.length].size,
        state: "success",
        percentage: 100
      }
      tableData.push(fileInformation);
    }
    function uploadFail(e){
      console.log(e);
    }
    return {fileList,fileInfomations,beforeUpload,uploadSuccess,uploadFail}
  }
}
</script>

<style scoped>

</style>