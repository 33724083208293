<template>
  <div class="root">
    <div class="title-box">
      隆基股份
    </div>
    <info-table v-if="showDetail" :table-header="tableHeader" :table-data="tableData"></info-table>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable";
import API from "@/plugin/axiosInstance";
import {useRouter} from "vue-router";
import {onBeforeMount, reactive, ref} from "vue";
export default {
  name: "stockDetail",
  components:{
    infoTable,
  },
  setup(){
    const tableHeader = [
      {value:"股票名称",label:"stock_name",width:"25%"},
      {value:"股票代码",label:"stock_code",width:"25%"},
      {value:"股票描述",label:"stock_detail",width:"25%"},
      {value:"所属板块",label:"belong_plate",width:"25%"},
      {value:"选中价格",label:"current_price",width:"50%"},
      {value:"选中日期",label:"create_date",width:"50%"},
      {value:"标签方法",label:"tag_or_method",width:"50%"},
      {value:"方法描述",label:"description",width:"50%"},
    ];
    let tableData = reactive({
      stock_name:"隆基绿能",
      stock_code:"601012",
      stock_detail:"光伏、华为、HJT电池",
      belong_plate:"光伏、华为、HJT电池",
      current_price:"26.26",
      create_date:"2023-09-23",
      tag_or_method:"两连跌后放量上涨",
      description:"底部放量上涨。",
    });
    let showDetail = ref(false);
    const routerParam = useRouter();
    let stockId = "";
    if (routerParam.currentRoute.value.query.id === undefined) {
      stockId = "373ca703c71f664de0eeba45356b843a";
    } else {
      stockId = routerParam.currentRoute.value.query.id;
    }
    onBeforeMount(()=>{
      API({
        url:"getStockInfo?id="+stockId,
        method:"get"
      }).then((res)=>{
        tableData.stock_name=res.data.data[0].stock_name;
        tableData.stock_code=res.data.data[0].stock_code;
        tableData.stock_detail=res.data.data[0].belong_plate;
        tableData.belong_plate=res.data.data[0].belong_plate;
        tableData.current_price=res.data.data[0].optional_price;
        tableData.create_date=res.data.data[0].update_date;
        tableData.tag_or_method=res.data.data[0].tag_or_method;
        tableData.description=res.data.data[0].description;
        showDetail.value = true;
      })
    })


    return {
      tableHeader,tableData,showDetail
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  width: 100%;

  .title-box {
    border-bottom: 1px solid #acacac;
    margin: 20px 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 800;
  }

}
</style>