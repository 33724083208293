
function dealStandardDate(date){
    let dealDate;
    if(date instanceof Date){
        dealDate = new Date(date);
    }else {
        dealDate = new Date();
    }
    let monthStr = (dealDate.getMonth() + 1) > 9 ? (dealDate.getMonth() + 1) : "0" + (dealDate.getMonth() + 1);
    let dayStr = dealDate.getDate() > 9 ? dealDate.getDate() : "0" + dealDate.getDate();
    let dateStr = dealDate.getFullYear() + "-" + monthStr + "-" + dayStr;
    return dateStr;
}


export default dealStandardDate