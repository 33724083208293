<template>
  <div class="m-bronze-scheme" style="width: 100%">
    <el-container>
      <el-header style="text-align: left; font-size: 0.75rem;line-height: 3rem;height: 3rem">
        <el-date-picker
            v-model="dateStart"
            type="date"
            placeholder="选择开始日期">
        </el-date-picker>
        <el-date-picker
            v-model="dateEnd"
            type="date"
            placeholder="选择结束日期"
            style="margin-left: 20px;">
        </el-date-picker>
        <el-input
            placeholder="股票代码"
            v-model="stockCode"
            clearable style="width: 9.375rem;margin-left: 1.25rem;margin-right: 1.25rem;">
        </el-input>
        <el-button type="primary" size="medium" @click="searchGetData">
          <el-icon style="vertical-align: middle;">
            <search/>
          </el-icon>
          <span style="vertical-align: middle;">搜索</span>
        </el-button>
      </el-header>
      <el-main style="padding: 4px;">
        <table-public @deleteStock="deleteStock" v-if="tableData.length!==0" :testStr="testStr"
                      :tableHeader="tableHeader" :tableData="tableData"></table-public>
        <el-pagination
            layout="prev, pager, next" :hide-on-single-page="true"
            :total="pageLength"
            :page-size="12"
            v-model:current-page="pageIndex"
            @current-change="proPage" style="position: absolute;bottom: 20px">
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {watch, ref, inject, reactive, onBeforeMount} from "vue";
import tablePublic from "@/components/tablePublic";
import dealStandardDate from "@/tool/dealDate";

export default {
  name: 'stockManager',
  components: {
    tablePublic
  },
  setup() {
    const API = inject("API");
    const tableHeader = reactive([
      {label: "stockName", value: "股票名称", variety: "detail", router: 'StockDetail'},
      {label: "belongPlate", value: "所属板块"},
      {label: "stockCode", value: "股票代码", variety: "detail", router: 'StockAnalyze', param: 'stockCode'},
      {label: "tagOrMethod", value: "标签方法", variety: "detail", router: 'MethodAnalyze', param: 'tagOrMethod'},
      {label: "updateDate", value: "自选时间"},
      {label: "isBuy", value: "是否买入", variety: "input", width: "90"},
      {label: "turnoverRate", value: "换手率"},
      {label: "control", value: "删除", variety: "func", width: "90", funcName: "deleteStock"},
    ]);
    let testStr = ref("测试");
    let tableData = reactive([]);
    let allTableData = [];
    let pageLength = ref(1);
    let pageIndex = ref(1);
    let dateStart = ref("");
    let dateEnd = ref("");
    let stockCode = ref("");
    let showTable = ref(false);
    let url = "";
    let imgSrc = ref("");
    let tempTableData = reactive([]);
    //const routerParam = useRouter();
    const fileStates = reactive([
      {value: "ok", label: "完好"}, {value: "delete", label: "删除"}, {value: "fail", label: "有损"}
    ])
    const myHomeVueGet = ref(null);//vue3获取dom元素。
    function proPage(page) {
      pageIndex.value = page;
      tableData.splice(0, tableData.length);
      for (let i = (page - 1) * 12; i < tempTableData.length; i++) {
        if (tableData.length < 12) {
          tableData.push(tempTableData[i]);
        }
      }
      //getData(page-1)
    }

    function getData(code,start,end) {
      tableData.splice(0, tableData.length);
      if(!start){
        start = '2023-08-01';
      }
      if (!end){
        end = dealStandardDate();
      }
      API({
        url: "getStockByCode?stockCode="+code+"&startTime=" + start + "&endTime=" + end,
        method: 'get',
        // headers: {
        //   "Acs-Token": "1695333604055_1695352705403_B5MOK1w9o4GXlP+k1asu0m34fbL33apTSxTN5JzRxun/Z72rjUI6cWmQ8JpEAswHgJaZOyNCmitkZdMm2gJO7pVJ9SbgVKEV+eYTbDhy/LKScJX+XjJtwFlFnx1NzgGLroMsNVf//IIBq69mYlm+rSCTvK2cMgSajIme78fbYT+Sb+cTQIZ/grVu6uRr3cV9stKPKAbIwXblonoAXGGbgQCmBLLrOdGymUsiaol7z30ucnrYxxv7A/QPeuco1tSc5PNzwl0gr+aSmQteFEU8AhacZmPhAj6YhnywaPIEwcqi9jjvWXmA65A0MIwv1pjpRn0WLNPNxL+EVK5Q3Adj4GJTWRf76LoqHIbMVbTelWFlim3pytqCNguCqRrLoD3j9QU9QJJtq/RW0Twhy7MeKQ==",
        //   "Cookie": "BIDUPSID=436092508C30653A0D51E6A61253189C; "
        // }
      }).then((response) => {
        const arr = response.data.data;
        tempTableData = arr.filter((currentValues) => {
          if (currentValues.is_delete === 0 || currentValues.is_delete === "0") return currentValues
        }).map((currentValue, index) => {//当前内容、索引、全部元素,arr
          return {
            id: currentValue.id,
            indexNumber: index + 1,
            stockName: currentValue.stock_name,
            belongPlate: currentValue.belong_plate,
            stockCode: currentValue.stock_code,
            tagOrMethod: currentValue.tag_or_method,
            updateDate: currentValue.update_date,
            isBuy: currentValue.is_buy,
            turnoverRate: currentValue.turnover_rate,
            myControl: 1
          }
        })
        //console.log(tempTableData);
        showTable.value = true;
        testStr.value = "test";
        for (let i = 0; i < tempTableData.length; i++) {
          //if (tableData.length < 12) {
            tableData.push(tempTableData[i]);
          //}
        }
      })
    }

    function searchGetData() {
      pageIndex.value = 1;
      const startTime = dealStandardDate(dateStart.value);
      const endTime = dealStandardDate(dateEnd.value);
      if(startTime===endTime){
        getData(stockCode.value);
      }else {
        getData(stockCode.value,startTime,endTime);
      }

      //proPage(1);
    }

    function clearImgsrc() {
      imgSrc.value = "";
    }

    watch([dateStart, dateEnd], ([dateStartValue, preDateStart], [dateEndValue, preDateEnd]) => {
      if ((typeof preDateEnd) === "object" && preDateEnd !== null) {
        if ((typeof dateStartValue) === "object" && dateStartValue !== null) {
          if (dateStartValue < preDateEnd) {
            dateStart.value = "";
            alert("需要在结束日期之前！");
          }
        }
      }
      if ((typeof preDateStart) === "object" && preDateStart !== null) {
        if ((typeof dateEndValue) === "object" && dateEndValue !== null) {
          if (dateEndValue > preDateStart) {
            dateEnd.value = "";
            alert("需要在开始日期之后！");
          }
        }
      }
    })

    function deleteStock(e) {
      console.log(e);
      API({
        url: "deleteStock",
        method: "post",
        data: {
          id: e.id
        }
      }).then(() => {
        location.reload();
      })
    }

    //getData();
    onBeforeMount(() => {
      getData("");
    })
    return {
      tableHeader,
      tableData,
      allTableData,
      pageLength,
      testStr,
      pageIndex,
      showTable,
      dateStart,
      dateEnd,
      stockCode,
      url,
      myHomeVueGet,
      imgSrc,
      fileStates,
      tempTableData,
      proPage,
      searchGetData,
      clearImgsrc,
      deleteStock
    }
  },

}
</script>

<style>
</style>

