<template>
  <div class="bottom-link">
    <el-link style="margin-right: 4px" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202001530" type="info" target="_blank">
      <img class="police-img" src="../assets/备案图标.png" alt="">川公网安备 51012202001530号</el-link>
    <el-link href="https://beian.miit.gov.cn/" type="info" target="_blank">蜀ICP备2023009892号</el-link>
  </div>
</template>

<script>
export default {
  name: "bottomLink"
}
</script>

<style scoped>
.bottom-link{
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 1001;
}
</style>