<template>
  <div id="app">
    <div style="text-align: center;width: 100%;height: 3.125rem;">
      <div id="MyTitle" style="font-size: 1.875rem;font-weight: 700;margin-left: 1.25rem;margin-top: 0.625rem;display: block;color: #409EFF;">
        {{ count }}</div>
    </div>
    <el-container style="border: 1px solid #eee;height: 90vh;">
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu :default-active="activeShift" :router="true" :collapse="isCollapse">
          <el-sub-menu index="1">
            <template #title><el-icon><Folder /></el-icon>
              <span>文件管理系统</span></template>
            <el-menu-item-group>
              <el-menu-item index="/ManagerPage/FileUpload" v-if="netChangeComponent">文件上传</el-menu-item>
              <el-menu-item index="/ManagerPage">文件下载</el-menu-item>
              <el-menu-item index="/ManagerPage/FileManager">文件管理</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-sub-menu index="2">
            <template #title><el-icon><OfficeBuilding /></el-icon>
              <span>股票分析系统</span></template>
            <el-menu-item-group>
              <el-menu-item index="/ManagerPage/StockInput" v-if="netChangeComponent">股票添加</el-menu-item>
              <el-menu-item index="/ManagerPage/StockManager">股票管理</el-menu-item>
              <el-menu-item index="/ManagerPage/StockAnalyze">股票分析</el-menu-item>
              <el-menu-item index="/ManagerPage/StockDetail">股票详情</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </el-menu><!--:collapse="isCollapse"-->
      </el-aside>
      <router-view></router-view>
    </el-container>
    <bottom-link></bottom-link>
  </div>
</template>

<script>
import bottomLink from "@/components/bottomLink";
import {onMounted, provide, nextTick, ref, watch} from "vue";
import {useStore} from 'vuex';
import {useRouter} from "vue-router";



export default {
  name: "App",
  components: {
    // fileUpload,
    // bronzeScheme,
    // historyData,
    bottomLink
  },
  setup(){
    //provide('echarts',echarts);
    let isCollapse = ref(false);
    let activeShift = ref("/ManagerPage/FileDownload");
    const routerParam = useRouter();
    watch(()=>routerParam.currentRoute.value.path,(newVal)=>{
      if(newVal==="/ManagerPage"){
        activeShift.value = "/ManagerPage/FileDownload";
      }else {
        activeShift.value = newVal;
      }
    })
    if(routerParam.currentRoute.value.path==="/ManagerPage"){
      activeShift.value = "/ManagerPage/FileDownload";
    }else {
      activeShift.value = routerParam.currentRoute.value.path;
    }
    const store = useStore()
    provide('store',store);
    let tableData = [];
    let componentSwitch = ref(1);
    let count = "CaptainLuo File Manager";
    let netChangeComponent = ref(true);
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath)
    }
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath)
    }
    async function increment(){
      count++;
      await nextTick();
      console.log(count);
    }
    function checkDoMain(){
      //let domain = document.domain;//获取路径，以用于区分内外网，避免跨域。
      /*if(domain!=="10.212.100.126"){//&&domain!=="localhost"
        this.netChangeComponent = false;
        //this.componentSwitch=2;
      }*/
    }
    onMounted(()=>{
      checkDoMain();
    })
    return {tableData,componentSwitch,netChangeComponent,count,activeShift,isCollapse,
      handleOpen,handleClose,checkDoMain,increment}
  },
}
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 1.875rem;
}

.el-aside {
  color: #333;
}
.uploader-example {
}
.uploader-example .uploader-btn {
  margin-right: 0;
}
.uploader-example .uploader-list {
  border: 0;
  max-height: 27.5rem;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.uploader-list{
  display: none;
}
</style>