<template>
  <div class="analyze-root">
    <div class="select-box">
      <el-select v-model="daysValue" class="m-2" placeholder="Select">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </div>
    <div ref="echartsBox" style="width: 80vw; height: 300px;">
    </div>
  </div>

</template>

<script>
import {inject, onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import API from "@/plugin/axiosInstance";

export default {
  name: "methodAnalyze",
  setup() {
    const echarts = inject('echarts');
    let myChart = null;
    const echartsBox = ref(null);
    const routerParam = useRouter();
    let apiData = [];
    const daysValue = ref('')
    const options = [
      {
        value: 'one_day',
        label: '1天',
      },
      {
        value: 'two_day',
        label: '2天',
      },
      {
        value: 'three_day',
        label: '3天',
      },
      {
        value: 'five_day',
        label: '5天',
      },
      {
        value: 'ten_day',
        label: '10天',
      },
      {
        value: 'more_day',
        label: '全部',
      },
    ]
    onMounted(() => {
      API({
        url: "getTagOrMethod?tagOrMethod=" + routerParam.currentRoute.value.query.commonParam,
        method: "get"
      }).then((res) => {
        console.log(res.data.data);
        const stockArr = res.data.data;
        //解决echarts在vue中切换到其他页面再返回来就不显示的问题
        echartsBox.value.setAttribute("_echarts_instance_", "");
        myChart = echarts.init(echartsBox.value);
        const data = stockArr.map((currentValue)=>{
          return (currentValue.current_profit/currentValue.optional_price).toFixed(2);
        });
        apiData = stockArr;
        const positive = [];
        const negative = [];
        for (let i = 0; i < data.length; ++i) {
          if (data[i] >= 0) {
            positive.push(data[i]);
            negative.push('-');
          } else {
            positive.push('-');
            negative.push(data[i]);
          }
        }
        // 绘制图表
        myChart.setOption({
          title: {
            text: routerParam.currentRoute.value.query.commonParam
          },
          legend: {},
          tooltip: {},
          dataZoom: [
            {
              id: 'dataZoomX',
              type: 'slider',
              xAxisIndex: [0],
              filterMode: 'filter',
              startValue:0,
              endValue:10,
            },
          ],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            splitLine: {show: false},
            axisLabel: { interval: 0, rotate: 30 },
            data: stockArr.map((currentValue)=>{//
              return currentValue.stock_name+"/"+currentValue.date;
            })
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '盈利',
              type: 'bar',
              stack: 'all',
              data: positive,
              itemStyle: {
                color: '#f33'
              }
            },
            {
              name: '亏损',
              type: 'bar',
              stack: 'all',
              data: negative,
              itemStyle: {
                color: '#25f605'
              }
            }
          ]
        });
        window.addEventListener('resize', function () {
          myChart.resize();
        })
      })
    })
    watch(
        daysValue,
        (newVal) => {
          const data = apiData.filter((currentValue)=>{
            return currentValue[newVal];
          }).map((currentValue)=>{
            return (currentValue[newVal]/currentValue.optional_price).toFixed(2);
          });
          const positive = [];
          const negative = [];
          for (var i = 0; i < data.length; ++i) {
            if (data[i] >= 0) {
              positive.push(data[i]);
              negative.push('-');
            } else {
              positive.push('-');
              negative.push(data[i]);
            }
          }
          myChart.setOption({
            title: {
              text: routerParam.currentRoute.value.query.commonParam
            },
            legend: {},
            tooltip: {},
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              splitLine: {show: false},
              data: apiData.filter((currentValue)=>{
                return currentValue[newVal];
              }).map((currentValue)=>{//
                return currentValue.stock_name+"/"+currentValue.date;
              })
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '盈利',
                type: 'bar',
                stack: 'all',
                data: positive,
                itemStyle: {
                  color: '#f33'
                }
              },
              {
                name: '亏损',
                type: 'bar',
                stack: 'all',
                data: negative,
                itemStyle: {
                  color: '#25f605'
                }
              }
            ]
          });
        }
    )
    return {echartsBox, daysValue, options}
  }
}
</script>

<style scoped>
.analyze-root {
  position: relative;
}

.select-box {
  position: absolute;
  right: 4%;
  top: 0;
  z-index: 100;
}
</style>