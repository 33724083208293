<template>
  <div class="input-root">
    <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="12%"
        class="stock-ruleForm"
        :size="formSize"
        status-icon
    >
      <el-form-item label="股票名称" prop="stockName">
        <el-input v-model="ruleForm.stockName"/>
      </el-form-item>
      <el-form-item label="股票代码" prop="stockCode">
        <el-input v-model="ruleForm.stockCode"/>
      </el-form-item>
      <el-form-item label="所属板块" prop="belongPlate">
        <el-input v-model="ruleForm.belongPlate"/>
      </el-form-item>
      <el-form-item label="价格"  prop="optionalPrice">
        <el-input v-model="ruleForm.optionalPrice"/>
      </el-form-item>
      <el-form-item label="成交量" prop="turnover">
        <el-input v-model="ruleForm.turnover"/>
      </el-form-item>
      <el-form-item label="换手率" prop="turnoverRate" >
        <el-input v-model="ruleForm.turnoverRate"/>
      </el-form-item>
      <el-form-item label="标签方法" prop="tagOrMethod" >
        <el-input v-model="ruleForm.tagOrMethod"/>
      </el-form-item>
      <el-form-item label="是否买入" prop="isBuy">
        <el-radio-group v-model="ruleForm.isBuy">
          <el-radio label="是"/>
          <el-radio label="否"/>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选中时间">
        <el-date-picker
            v-model="ruleForm.date"
            type="date"
            placeholder="选择一个日期"
            clearable
        />
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input v-model="ruleForm.description" type="textarea"/>
      </el-form-item>
      <el-form-item class="button-control">
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          添加
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
import {inject, reactive, ref} from 'vue';
import {ElMessage} from "element-plus";

export default {
  name: "stockInput",
  setup() {
    const API = inject("API");
    const formSize = ref('default');
    const ruleFormRef = ref();
    const ruleForm = reactive({
      stockName: '',//股票名称
      stockCode: '',//股票代码
      belongPlate: '',//所属板块
      optionalPrice: '',//自选价格
      turnover: '',//成交量
      turnoverRate: '',//换手率
      tagOrMethod: '',//标签方法
      isBuy: '',//是否买入
      description:'',//描述
      date:'',//关注时间
    })
    const rules = reactive({
      stockName: [
        {required: true, message: '请输入股票名称。', trigger: 'blur'},
        {min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur'},
      ],
      stockCode: [
        {
          required: true,
          message: '请输入股票代码。',
          trigger: 'blur',
        },
      ],
      belongPlate: [
        {
          required: true,
          message: '请输入板块。',
          trigger: 'blur',
        },
      ],
      optionalPrice: [
        {
          required: true,
          message: '请输入价格。',
          trigger: 'blur',
        },
      ],
      tagOrMethod: [
        {
          required: true,
          message: '请输入选择标签和原因',
          trigger: 'blur',
        },
      ],
      // type: [
      //   {
      //     type: 'array',
      //     required: true,
      //     message: 'Please select at least one activity type',
      //     trigger: 'change',
      //   },
      // ],
      isBuy: [
        {
          required: true,
          message: '请选择是否买入。',
          trigger: 'change',
        },
      ],
      description: [
        {required: true, message: '请补充说明或者填无。', trigger: 'blur'},
      ],
    })
    const submitForm = async (formEl) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          //提交信息
          API({
            url:"uploadStock",
            method:"post",
            data:ruleForm
          }).then((e)=>{
            if(e.status!==200){
              ElMessage.warning({
                message: '添加失败。',
                type: 'warning',
              })
              return true
            }
            formEl.resetFields()
          })
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    const resetForm = (formEl) => {
      if (!formEl) return
      formEl.resetFields()
    }

    return {formSize, ruleFormRef, ruleForm, rules, submitForm, resetForm}
  }
}

</script>

<style lang="scss" scoped>
.input-root{
  width: 100%;
  margin-top: 60px;
  overflow: auto;
  display: flex;
  justify-content: center;
  //align-items: center;

  .stock-ruleForm{
    width: 50%;

    .button-control{
      width: 100%;
      height: 100px;


      /deep/ .el-form-item__content{
        margin-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>