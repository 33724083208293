import { createApp } from 'vue'
//import ElementPlus from 'element-plus'
import App from './App.vue'
// import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconVue from '@element-plus/icons-vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import API from './plugin/axiosInstance';
import Router from "@/router/index";
import store from "@/store/index";
import * as echarts from "echarts";


const app = createApp(App);
app.provide('API',API);
app.provide('echarts',echarts);
for (const [key, component] of Object.entries(ElementPlusIconVue)) {
    app.component(key, component)
}
// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
//结束


app.use(ElementPlus).use(Router).use(store)
.mount('#app')
