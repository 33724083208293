<template>
  <div class="analyze-root">
    <div class="stock-info">
      <div class="title">
        {{ stockName }}
      </div>
      <div class="origin-price">成本价:{{originPrice}}</div>
    </div>
    <div ref="echartsBox" style="width: 85vw; height: 400px;">
    </div>
    <div class="maintain-info">
      <div class="title">信息维护</div>
      <div class="maintain-content">
        <div class="select-title">请选择时间:</div>
        <div class="time-select">
          <el-date-picker
              v-model="selectDate"
              type="date"
              placeholder="选择一个日期"
              size="default"
          />
        </div>
        <div class="input-title">请输入价格:</div>
        <div class="price-input">
          <el-input v-model="inputPrice" placeholder="请输入价格"/>
        </div>
        <div class="submit-button">
          <el-button @click="submitPrice">提交</el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {inject, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import dealStandardDate from "@/tool/dealDate";
import API from "@/plugin/axiosInstance";
import {ElMessage} from "element-plus";

export default {
  name: "stockAnalyze",
  setup() {
    const echarts = inject('echarts');
    const echartsBox = ref(null);
    const routerParam = useRouter();
    let selectDate = ref("");
    let inputPrice = ref('');
    let stockName = ref("隆基绿能");
    let originPrice = ref("26.26");
    onMounted(() => {
      let stockCode = "";
      if (routerParam.currentRoute.value.query.commonParam === undefined) {
        stockCode = "601012";
      } else {
        stockCode = routerParam.currentRoute.value.query.commonParam;
      }
      API({
        url: "getStockChange?stockCode=" + stockCode,
        method: 'get'
      }).then((res) => {
        if (res.data.data.length === 0) {
          ElMessage.warning({
            message: '没有数据，请联系管理员',
            type: 'warning',
          })
          return
        }
        stockName.value = res.data.data[0].stock_name;
        //解决echarts在vue中切换到其他页面再返回来就不显示的问题
        echartsBox.value.setAttribute("_echarts_instance_", "");
        let myChart = echarts.init(echartsBox.value);
        const data = res.data.data.map((currentValue, index) => {
          if (index === 0) return 0;
          else return parseInt(currentValue.price * 100) - parseInt(res.data.data[index - 1].price * 100);
        });
        //const data = [900, 345, 393, -108, -154, 135, 178, 286, -119, -361, -203];[0.007, 0.0023, -0.0141, -0.0081, -0.001, 0.002, 0.0432, -0.0064, -0.035, -0.001, -0.009]
        const help = [];
        const positive = [];
        const negative = [];
        const breakDownPositive = [];
        const breakDownNegative = [];
        const priceRange = [];
        //let currentPrice = res.data.data[0].price;原始价格
        originPrice.value = res.data.data[0].price;
        let currentRange = 0;//累计涨幅
        for (let i = 0; i < data.length; ++i) {
          if (data[i] >= 0) {
            negative.push('-');
            breakDownNegative.push('-');
            if (currentRange < 0 && -currentRange < data[i]) {
              breakDownPositive.push(currentRange);
              help.push(0);
              positive.push(data[i] + currentRange);
              currentRange = currentRange + data[i];
            } else if (currentRange >= 0) {
              i === 0 ? help.push(0) : help.push(currentRange);
              positive.push(data[i]);
              breakDownPositive.push('-');
              currentRange = currentRange + data[i];
            } else {
              positive.push(-data[i]);
              breakDownPositive.push('-');
              currentRange = currentRange + data[i];
              i === 0 ? help.push(0) : help.push(currentRange);
            }
          } else {
            positive.push('-');
            breakDownPositive.push('-');
            if (currentRange > 0 && currentRange < -data[i]) {
              breakDownNegative.push(currentRange);
              help.push(0);
              negative.push(data[i] + currentRange);
              currentRange = currentRange + data[i];
            } else if (currentRange <= 0) {
              i === 0 ? help.push(0) : help.push(currentRange);
              negative.push(data[i]);
              breakDownNegative.push('-');
              currentRange = currentRange + data[i];
            } else {
              negative.push(-data[i]);
              breakDownNegative.push('-');
              currentRange = currentRange + data[i];
              i === 0 ? help.push(0) : help.push(currentRange);
            }
          }
          priceRange.push(currentRange);
        }
        // 绘制图表
        myChart.setOption({
          // title: {
          //   text: '隆基股份'
          // },
          tooltip: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            splitLine: {show: false},
            data: res.data.data.map((currentValue) => {
              return currentValue.date_str
            })
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              type: 'bar',
              stack: 'all',
              itemStyle: {
                normal: {
                  barBorderColor: 'rgba(0,0,0,0)',
                  color: 'rgba(0,0,0,0)'
                },
                emphasis: {
                  barBorderColor: 'rgba(0,0,0,0)',
                  color: 'rgba(0,0,0,0)'
                }
              },
              data: help
            },
            {
              name: 'positive',
              type: 'bar',
              stack: 'all',
              data: positive,
              itemStyle: {
                color: '#f60505'
              }
            },
            {
              name: 'breakDownPositive',
              type: 'bar',
              stack: 'all',
              data: breakDownPositive,
              itemStyle: {
                color: '#f60505'
              }
            },
            {
              name: 'negative',
              type: 'bar',
              stack: 'all',
              data: negative,
              itemStyle: {
                color: '#25f605'
              }
            },
            {
              name: 'breakDownNegative',
              type: 'bar',
              stack: 'all',
              data: breakDownNegative,
              itemStyle: {
                color: '#25f605'
              }
            },
            {
              name: '价格折线',
              data: priceRange,
              type: "line",
              itemStyle: {
                color: '#f6be05'
              }
            }
          ]
        });
        window.addEventListener('resize', function () {
          myChart.resize();
        })
      })
    })

    function submitPrice() {
      const dealDate = dealStandardDate(selectDate.value);
      API({
        url: "addStockPrice",
        method: "post",
        data: {
          stockCode: routerParam.currentRoute.value.query.commonParam,
          dateStr: dealDate,
          price: inputPrice.value
        }
      }).then((res) => {
        if (res.data.code === 200) {
          selectDate.value = "";
          inputPrice.value = "";
          location.reload();
        } else if (res.data.code === 201) {
          ElMessage.warning({
            message: res.data.message,
            type: 'warning',
          })
        } else {
          ElMessage.warning({
            message: '请确认信息完整。',
            type: 'warning',
          })
        }
      })
    }

    return {echartsBox, originPrice, selectDate, stockName, inputPrice, submitPrice}
  }
}
</script>

<style lang="scss" scoped>
.stock-info {
  margin: 0 50px -10px 20px;
  position: relative;

  .title {
    border-bottom: 1px solid #acacac;
    margin: 20px 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 800;
  }
  .origin-price{
    position: absolute;
    right: 10px;
    top: 0;
  }
}

.maintain-info {
  margin: 40px 50px 0 20px;

  .title {
    border-bottom: 1px solid #acacac;
    margin: 20px 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 800;
  }

  .maintain-content {
    display: flex;
    width: 100%;
    margin-top: 10px;

    .select-title {
      width: 15%;
      height: 42px;
      line-height: 42px;
    }

    .time-select {
      width: 25%;

      /deep/ .el-input {
        width: 100%;
      }
    }

    .input-title {
      width: 15%;
      height: 42px;
      line-height: 42px;
    }

    .price-input {
      width: 25%;
      height: 42px;
      line-height: 42px;
    }

    .submit-button {
      width: 20%;
      height: 42px;
      line-height: 42px;
    }
  }

}
</style>