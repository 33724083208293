import axios from "axios";


const API = axios.create({
    baseURL:"https://www.captainluo.cn/api/",//,"http://127.0.0.1:8090/api/"
    timeout:18000
})
// API.interceptors.request.use(function (config){
//     config.headers["Acs-Token"] = `1695333604055_1695352705403_B5MOK1w9o4GXlP+k1asu0m34fbL33apTSxTN5JzRxun/Z72rjUI6cWmQ8JpEAswHgJaZOyNCmitkZdMm2gJO7pVJ9SbgVKEV+eYTbDhy/LKScJX+XjJtwFlFnx1NzgGLroMsNVf//IIBq69mYlm+rSCTvK2cMgSajIme78fbYT+Sb+cTQIZ/grVu6uRr3cV9stKPKAbIwXblonoAXGGbgQCmBLLrOdGymUsiaol7z30ucnrYxxv7A/QPeuco1tSc5PNzwl0gr+aSmQteFEU8AhacZmPhAj6YhnywaPIEwcqi9jjvWXmA65A0MIwv1pjpRn0WLNPNxL+EVK5Q3Adj4GJTWRf76LoqHIbMVbTelWFlim3pytqCNguCqRrLoD3j9QU9QJJtq/RW0Twhy7MeKQ==`;
//     return config
// })

export default API