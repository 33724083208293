<template>
  <div class="public-table-root">
    <el-table :data="tableData" max-height="80vh">
      <el-table-column v-if="canSelect" type="selection" width="35"></el-table-column>
      <template v-for="(item,index) in tableHeader" :key="index">
        <el-table-column v-if="item.variety==='input'" align="center" :prop="item.label" :label="item.value"
                         :width="item.width">
          <template #default="scope">
            <el-input placeholder="请输入内容" v-model="scope.row[item.label]"></el-input>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.variety==='detail'&&!item.param" align="center" :prop="item.label" :label="item.value" :width="item.width">
          <template #default="scope">
            <span>
              <router-link class="goto-detail" :to="{name:item.router,query:{id:scope.row.id}}">{{ scope.row[item.label] }}</router-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.variety==='detail'&&item.param" align="center" :prop="item.label" :label="item.value" :width="item.width">
          <template #default="scope">
            <span>
              <router-link class="goto-detail" target="_blank" :to="{name:item.router,query:{commonParam:scope.row[item.param]}}">{{ scope.row[item.label] }}</router-link>
            </span>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.variety==='select'" :prop="item.label" :label="item.value" :width="item.width">
          <template #default="scope">
            <el-input placeholder="请输入内容" v-model="scope.row[item.label]" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.variety==='func'" :prop="item.label" :label="item.value" :width="item.width">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="executeFunc(item.funcName,scope.row)">{{ item.value }}</el-button>
          </template>
        </el-table-column>
        <el-table-column v-else align="center" :prop="item.label" :label="item.value" :width="item.width">
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>

import {reactive} from "vue";

export default {
  name: "tablePublic",
  props: {
    tableHeader: {
      type: Array
    },
    tableData: {
      type: Array
    },
    canSelect: {
      type: Number,
      default: 0
    },
  },
  setup(props,{emit}) {
    function executeFunc(funcStr,data){
      emit(funcStr,data);
    }
    let innerTableDate = reactive([]);
    //
    return {
      innerTableDate,executeFunc
    }
  }
}
</script>

<style scoped>

.goto-detail {
  text-decoration: none;
  color: #409EFF;
}
.goto-detail:visited{
  color: #409EFF;
}


</style>