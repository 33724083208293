import {createStore} from "vuex";

const store = createStore({
    state:{
        userInfo:{},
    },
    getters:{
        getUserInfo(state){
            return state.userInfo
        }
    },
    mutations:{
        updateUserInfo(state,userInfo){
            state.userInfo = userInfo;
        }
    },
    actions:{
        updateUserInfoAsync(state,userInfo){
            new Promise(function (resolve){
                console.log(userInfo);
                resolve();
            }).then(()=>{
                console.log(state.userInfo)
            })
        }
    }

})
export default store