<template>
  <div class="m-bronze-scheme" style="width: 100%">
    <el-container>
      <el-header style="text-align: left; font-size: 0.75rem;line-height: 3rem;height: 3rem">
        <el-date-picker
            v-model="dateStart"
            type="date"
            placeholder="选择开始日期">
        </el-date-picker>
        <el-date-picker
            v-model="dateEnd"
            type="date"
            placeholder="选择结束日期"
            style="margin-left: 20px;">
        </el-date-picker>
        <el-input
            placeholder="文件名称"
            v-model="fileName"
            clearable style="width: 9.375rem;margin-left: 1.25rem;margin-right: 1.25rem;">
        </el-input>
        <el-button type="primary" size="medium" @click="searchGetData">
          <el-icon style="vertical-align: middle;">
            <search/>
          </el-icon>
          <span style="vertical-align: middle;">搜索</span>
        </el-button>
      </el-header>
      <el-main style="padding: 4px;">
        <el-table :data="tableData" max-height="80vh">
          <el-table-column align="center" width="60" prop="indexNumber" label="序号">
          </el-table-column>
          <el-table-column align="center" prop="CreateDate" label="上传日期">
          </el-table-column>
          <el-table-column align="center" width="260" prop="fileName" :show-overflow-tooltip="true" label="文件名称">
          </el-table-column>
          <el-table-column align="center" width="240" prop="fileDesc" label="文件描述">
          </el-table-column>
          <el-table-column align="center" width="120" prop="fileType" label="文件类型">
          </el-table-column>
          <el-table-column align="center" width="120" prop="fileSize" label="文件大小">
          </el-table-column>
          <el-table-column align="center" width="120" prop="state" label="文件状态">
          </el-table-column>
          <el-table-column align="center" width="120" prop="downloadTime" label="下载次数">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-popover placement="left" :width="400" :show-after="400" trigger="click" @show="overlookFile(scope.$index, scope.row)" @after-leave="clearImgsrc">
                <template #reference>
                  <el-button size="small" type="primary">查看</el-button>
                </template>
                <el-image :src="imgSrc"></el-image>
              </el-popover>
              <el-button size="small" type="Success"
                         @click="downloadFile(scope.$index, scope.row)">下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next" :hide-on-single-page="true"
            :total="pageLength"
            :page-size="15"
            v-model:current-page="pageIndex"
            @current-change="proPage" style="position: absolute;bottom: 20px">
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {watch, onMounted, ref, inject, reactive} from "vue";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: 'fileDownload',
  setup() {
    const API = inject("API");
    const store = useStore();
    let tableData = reactive([]);
    let allTableData = [];
    let pageLength = ref(1);
    let pageIndex = ref(1);
    let dateStart = ref("");
    let dateEnd = ref("");
    let fileName = ref("");
    let url = "";
    let imgSrc = ref("");
    let tempTableData = [];
    const myHomeVueGet = ref(null);//vue3获取dom元素。
    function proPage(page) {
      pageIndex.value = page;
      tableData.splice(0,tableData.length);
      for (let i = (page-1)*15; i < tempTableData.length; i++) {
        if(tableData.length<15){
          tableData.push(tempTableData[i]);
        }
      }
      //getData(page-1)
    }
    function getData(firstPageIndex) {
      tableData.splice(0,tableData.length);
      let endTime = "";
      let startTime = "";
      if ((typeof dateStart.value) === "object" && dateStart.value !== null) {
        let startDate = new Date(dateStart.value);
        let monthStr = (startDate.getMonth() + 1) > 9 ? (startDate.getMonth() + 1) : "0" + (startDate.getMonth() + 1);
        let dayStr = startDate.getDate() > 9 ? startDate.getDate() : "0" + startDate.getDate();
        startTime = startDate.getFullYear() + "-" + monthStr + "-" + dayStr;
      }
      if ((typeof dateEnd.value) === "object" && dateEnd.value !== null) {
        let endDate = new Date(dateEnd.value);
        let monthStr = (endDate.getMonth() + 1) > 9 ? (endDate.getMonth() + 1) : "0" + (endDate.getMonth() + 1);
        let dayStr = endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate();
        endTime = endDate.getFullYear() + "-" + monthStr + "-" + dayStr;
      } else {
        let endDate = new Date();
        let monthStr = (endDate.getMonth() + 1) > 9 ? (endDate.getMonth() + 1) : "0" + (endDate.getMonth() + 1);
        let dayStr = endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate();
        endTime = endDate.getFullYear() + "-" + monthStr + "-" + dayStr;
      }
      API({
        url: "getOkFile?startTime=" + startTime + "&endTime=" + endTime + "&fileName=" + fileName.value,
        method: 'get'
      }).then((response) => {
        const arr = response.data.data;
        tempTableData = arr.map((currentValue, index) => {//当前内容、索引、全部元素,arr
          return {
            id: currentValue.id,
            indexNumber: index + 1,
            CreateDate: currentValue.update_date,
            fileName: currentValue.file_name,
            fileDesc: currentValue.file_desc,
            fileType: currentValue.file_type,
            fileSize: currentValue.file_size,
            state: "ok",
            downloadTime: currentValue.download_times,
            myControl: 1
          }
        })
        for (let i = firstPageIndex*15; i < tempTableData.length; i++) {
          if(tableData.length<15){
            tableData.push(tempTableData[i]);
          }
        }
        pageLength.value = tempTableData.length;
      })
    }
    function searchGetData() {
      pageIndex.value = 1;
      getData(0);
      //proPage(1);
    }
    function getComputeData() {
      let endTime = "";
      let startTime = "";
      if ((typeof dateStart.value) === "object" && dateStart.value !== null) {
        let startDate = new Date(dateStart.value);
        startTime = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate();
      }
      if ((typeof dateEnd.value) === "object" && dateEnd.value !== null) {
        let endDate = new Date(dateEnd.value);
        endTime = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate();
      }
      console.log(startTime + "" + endTime)
    }
    function overlookFile(index, row) {
      if(row.fileType!=="jpeg"&&row.fileType!=="jpg"&&row.fileType!=="png"&&row.fileType!=="ico"){
        ElMessage.warning({
          message: '只能查看图片类型。',
          type: 'warning',
        })
        return false
      }
      API({
        url:'overlookFileById?fileName='+row.fileName+'&fileType='+row.fileType+'&id='+row.id,
        method:'get'
      }).then((result)=>{
        imgSrc.value = result.data.data;
      })
    }
    function clearImgsrc(){
      imgSrc.value = "";
    }
    function downloadFile(index, row) {
      if(store.state.userInfo.can_download){
        API({
          url:'downloadFileById?id='+row.id,
          method:'get',
          responseType:'blob'
        }).then((result)=>{
          let blob = new Blob([result.data]);
          let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
          let a = document.createElement('a');
          a.href = url;
          a.download = row.fileName+'.'+row.fileType;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      }
    }

    watch([dateStart, dateEnd], ([dateStartValue, preDateStart], [dateEndValue, preDateEnd]) => {
      if ((typeof preDateEnd) === "object" && preDateEnd !== null) {
        if ((typeof dateStartValue) === "object" && dateStartValue !== null) {
          if (dateStartValue < preDateEnd) {
            dateStart.value = "";
            alert("需要在结束日期之前！");
          }
        }
      }
      if ((typeof preDateStart) === "object" && preDateStart !== null) {
        if ((typeof dateEndValue) === "object" && dateEndValue !== null) {
          if (dateEndValue > preDateStart) {
            dateEnd.value = "";
            alert("需要在开始日期之后！");
          }
        }
      }
    })
    onMounted(() => {
      getData(0);
    })
    return {
      tableData, allTableData, pageLength, pageIndex, dateStart, dateEnd, fileName, url, myHomeVueGet,imgSrc,
      proPage, searchGetData, getComputeData, overlookFile, downloadFile,clearImgsrc,
    }
  },

}
</script>

<style>
</style>

